import { Injectable } from '@angular/core';
import { Observable, from, concatMap } from 'rxjs';
import { User } from '../../models/user';
import { Router } from '@angular/router';
import { IbdSdkService } from '../common/ibd-sdk.service';
import {
  AuthenticateUserRequest,
  AuthenticatedUserResponse,
  LogoutUserDeviceRequest,
  LogoutUserDeviceResponse,
  UserResendVerificationRequest,
  UserResetPasswordRequest,
  UserSetPasswordRequest,
} from '@ct-ibd/cmn-ibd-typescript-sdk';
import { CookieService } from 'ngx-cookie-service';
import { appConstants } from './../../constants/app.constants';
import { ProgressLoaderService } from '../loader/progressLoader.service';
import { UserResendVerificationResponse } from '@ct-ibd/cmn-ibd-typescript-sdk/dist/src/user/user_resend_verification_response';

@Injectable()
export class AuthenticationService {
  constructor(
    private router: Router,
    private spinner: ProgressLoaderService,
    private cookieService: CookieService,
    public ibdSdkService: IbdSdkService
  ) {}

  login(user: User): Observable<AuthenticatedUserResponse | null> {
    const authenticateUserRequest = new AuthenticateUserRequest({
      email: user.email,
      password: user.password,
    });
    return from(
      this.ibdSdkService.ibd.authenticationManager.authenticateUser(
        authenticateUserRequest
      )
    );
  }

  saveAuthDetails(): void {
    this.cookieService.set(appConstants.LOGGED_IN, 'true');
    this.router.navigateByUrl('/home');
  }

  logoutUser(): Observable<LogoutUserDeviceResponse | null> {
    return from(this.ibdSdkService.loginCheck()).pipe(
      concatMap(() =>
        from(
          this.ibdSdkService.ibd.authenticationManager.logoutUserDevice(
            new LogoutUserDeviceRequest(
              localStorage.getItem(appConstants.REFRESH_TOKEN) || ''
            )
          )
        )
      )
    );
  }

  public getUserId(): string | null {
    return localStorage.getItem(appConstants.USER_ID);
  }

  public logout() {
    this.spinner.show();
    this.cookieService.delete(appConstants.LOGGED_IN);
    this.logoutUser().subscribe(() => {
      this.spinner.hide();
      localStorage.clear();
      this.router.navigateByUrl('/login');
    });
  }

  forgotPassword(email: any): Observable<any> {
    const resetPasswordRequest = new UserResetPasswordRequest(
      email.forgotPassword
    );
    return from(
      this.ibdSdkService.ibd.userManager.resetPassword(resetPasswordRequest)
    );
  }

  setPassword(setPasswordRequest: any): Observable<any> {
    const SetPasswordRequest = new UserSetPasswordRequest(
      setPasswordRequest.SetPassword.email,
      setPasswordRequest.SetPassword.code,
      setPasswordRequest.SetPassword.password
    );
    return from(
      this.ibdSdkService.ibd.userManager.setPassword(SetPasswordRequest)
    );
  }

  resendVerificationCode(
    email: string
  ): Observable<UserResendVerificationResponse> {
    const resendCodeRequest = new UserResendVerificationRequest(email);
    return from(
      this.ibdSdkService.ibd.userManager.resendVerification(resendCodeRequest)
    );
  }
}
